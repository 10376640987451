/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

.ruleNumberInput-input {
  min-width: var(--44px);
  text-align: center;
}

.ruleNumberInput-message {
  width: min-content;
  text-align: center;
}
